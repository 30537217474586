import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset};

  html {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Pretendard;
  }

  input, textarea, button {
    font-family: inherit;
  }

  a.navLink {
    font-weight: bold;
    text-decoration: none;
    color: blue;
  }
`;
export default GlobalStyle;
