import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { storage } from '../../util/firebase';
import { isEmptyString } from '../../util/function/validation';
import MemberDetail from '../../templates/MemberDetail';
import { createMemberQuery, updateMemberQuery } from '../../util/apollo/memberQueries';

const CreateMember: React.FC = () => {
  const [name, setName] = useState('');
  const [nameEng, setNameEng] = useState('');
  const [department, setDepartment] = useState('');
  const [departmentEng, setDepartmentEng] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [introductionEng, setIntroductionEng] = useState('');

  const [active, setActive] = useState<0 | 1>(0);
  const [generation, setGeneration] = useState('13');

  // 이미지
  const storageRef = storage.ref();
  const [file, setFile] = useState<File | null>(null);
  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] ?? null);
  };
  const onRemovePicture = () => {
    setFile(null);
  };

  // 생성 로직
  const [createMember, { loading, error }] = useMutation(createMemberQuery);
  const [updateMember] = useMutation(updateMemberQuery);
  const [loadingFile, setLoadingFile] = useState(false);
  const submit = async () => {
    if (isEmptyString(name) || isEmptyString(department)) {
      alert('이름, 학부를 입력해주세요.');
      return;
    }

    const newMember = (await createMember({
      variables: {
        name: name.trim(),
        nameEng: nameEng.trim(),
        department: department.trim(),
        departmentEng: departmentEng.trim(),
        generation,
        introduction,
        introductionEng,
        active,
      },
    })).data.createMember;

    if (file) {
      const fileRef = storageRef.child(`member/${newMember.id}/picture/${newMember.id}`);
      try {
        setLoadingFile(true);
        // firebase storage 파일 업로드
        await fileRef.put(file);
        // db picture 값 업데이트
        await updateMember({
          variables: {
            id: newMember.id,
            name: newMember.name,
            nameEng: newMember.nameEng,
            department: newMember.department,
            departmentEng: newMember.departmentEng,
            generation: newMember.generation,
            active: newMember.active,
            picture: `gs://stem-dc04f.appspot.com/member/${newMember.id}/picture/${newMember.id}`,
          },
        });
      } catch {
        alert('이미지 업로드 실패');
      } finally {
        setLoadingFile(false);
      }
    }
    alert('멤버 등록 성공!');
  };

  if (loading || loadingFile) return <p>생성 중입니다...</p>;
  if (error) return <p>에러가 발생했습니다.</p>;
  return (
    <div>
      <Title>멤버 생성</Title>
      <MemberDetail
        name={name}
        setName={setName}
        nameEng={nameEng}
        setNameEng={setNameEng}
        introduction={introduction}
        setIntroduction={setIntroduction}
        introductionEng={introductionEng}
        setIntroductionEng={setIntroductionEng}
        department={department}
        setDepartment={setDepartment}
        departmentEng={departmentEng}
        setDepartmentEng={setDepartmentEng}
        generation={generation}
        setGeneration={setGeneration}
        active={active}
        setActive={setActive}
        submit={submit}
        file={file}
        onChangePictureFile={onFileUpload}
        onClickRemovePicture={onRemovePicture}
        originalPicture={null}
      />
    </div>
  );
};

export default CreateMember;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;
