import firebase from 'firebase/app';
import '@firebase/storage';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyAKxUBcXWh448DClFUeW5Gkd7zsj6knUHk',
    authDomain: 'stem-dc04f.firebaseapp.com',
    projectId: 'stem-dc04f',
    storageBucket: 'stem-dc04f.appspot.com',
    messagingSenderId: '24855090544',
    appId: '1:24855090544:web:a2bf64b8a7aa04134d2bb5',
    measurementId: 'G-L2VK10VT7R',
  });
}

export const storage = firebase.storage();
