import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import {
  TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography,
} from '@material-ui/core';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import { useDropzone } from 'react-dropzone';
import fileToBase64 from '../util/function/image';
import { storage } from '../util/firebase';

type Props = {
  title: string;
  setTitle: (newState: string) => void;
  titleEng: string;
  setTitleEng: (newState: string) => void;
  body: string;
  setBody: (newState: string) => void;
  bodyEng: string;
  setBodyEng: (newState: string) => void;
  setAttachments: (newState: File[]) => void;
  priority: 0 | 1;
  setPriority: (newState: 0 | 1) => void;
  submit: () => void;
  onChangeThumbnailFile: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickRemoveThumbnail: () => void;
  onClickRemoveAttachments: () => void;
  thumbnailFile: File | null;
  originalThumbnail: string | null;
  originalAttachments: string | null;
}

const PostDetail: React.FC<Props> = ({
  title,
  setTitle,
  titleEng,
  setTitleEng,
  body,
  setBody,
  bodyEng,
  setBodyEng,
  setAttachments,
  priority,
  setPriority,
  submit,
  onChangeThumbnailFile,
  onClickRemoveThumbnail,
  onClickRemoveAttachments,
  thumbnailFile,
  originalThumbnail,
  originalAttachments,
}) => {
  const inputProps = (state: string, setState: (newState: string) => void) => ({
    value: state,
    onChange: (e: ChangeEvent<HTMLInputElement>) => setState(e.target.value),
  });

  const editor = useRef<Editor | null>(null);
  const editorEng = useRef<Editor | null>(null);
  useEffect(() => {
    editor.current = new Editor({
      el: document.querySelector('#body-editor') as HTMLElement,
      height: '500px',
      initialEditType: 'wysiwyg',
      initialValue: body || ' ',
      previewStyle: 'vertical',
      language: 'ko',
    });
    editorEng.current = new Editor({
      el: document.querySelector('#bodyEng-editor') as HTMLElement,
      height: '500px',
      initialEditType: 'wysiwyg',
      initialValue: bodyEng || ' ',
      previewStyle: 'vertical',
      language: 'ko',
    });
  }, [body, bodyEng]);

  const [thumbnailString, setThumbnailString] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      if (thumbnailFile) {
        setThumbnailString(await fileToBase64(thumbnailFile));
      } else {
        setThumbnailString(null);
      }
    })();
  }, [thumbnailFile]);

  useEffect(() => {
    (async () => {
      if (originalThumbnail) {
        const gsRef = storage.refFromURL(originalThumbnail);
        gsRef.getDownloadURL().then((url) => {
          setThumbnailString(url);
        });
      } else {
        setThumbnailString(null);
      }
    })();
  }, [originalThumbnail]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const onClickSubmit = () => {
    if (editor?.current) {
      setBody(editor.current.getHTML());
    }
    if (editorEng?.current) {
      setBodyEng(editorEng.current.getHTML());
    }
    if (acceptedFiles.length) {
      setAttachments(acceptedFiles);
    }
    submit();
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TextField id="title" label="제목" {...inputProps(title, setTitle)} />
      <TextField id="title-english" label="제목(영어)" {...inputProps(titleEng, setTitleEng)} />

      <FormControl>
        <InputLabel id="priority-label">상단고정</InputLabel>
        <Select
          labelId="priority-label"
          id="priority"
          value={priority}
          onChange={(e) => setPriority(Number(e.target.value) as 0 | 1)}
        >
          <MenuItem value={1}>No</MenuItem>
          <MenuItem value={0}>Yes</MenuItem>
        </Select>
      </FormControl>

      <br />
      <h3>한글 내용</h3>
      <div id="body-editor" />
      <h3>영어 내용</h3>
      <div id="bodyEng-editor" />

      <hr />

      <FormControl>
        <Typography variant="subtitle1" gutterBottom>썸네일 등록</Typography>
        <Input
          type="file"
          onChange={onChangeThumbnailFile}
          accept="image/*"
        />
        <MButton onClick={onClickRemoveThumbnail} variant="contained" size="small">썸네일 비우기</MButton>
        <Typography variant="subtitle2" gutterBottom>(썸네일 미리보기)</Typography>
        <Image src={thumbnailString ?? undefined} alt="" />
      </FormControl>

      <hr />

      <FormControl>
        {originalAttachments && (
          <>
            <Typography variant="subtitle1" gutterBottom>기존 첨부파일 목록</Typography>
            <ul>
              {(JSON.parse(originalAttachments)).map((fileName: string) => (
                <List key={fileName}>{fileName}</List>
              ))}
            </ul>
          </>
        )}

        <Typography variant="subtitle1" gutterBottom>새로운 첨부파일 등록</Typography>
        <div className="container">
          <Container {...getRootProps()}>
            <input {...getInputProps()} />
            <p>파일을 드래그하거나, 박스를 클릭하세요</p>
          </Container>
        </div>
        <ul>
          {acceptedFiles.map((file: File) => (
            <List key={file.name}>{file.name}</List>
          ))}
        </ul>

        <MButton onClick={onClickRemoveAttachments} variant="contained" size="small">첨부파일 비우기</MButton>
      </FormControl>

      <Button variant="contained" color="primary" onClick={onClickSubmit}>
        확인
      </Button>
    </Form>
  );
};

export default PostDetail;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
`;

const Input = styled.input`
  width: 10rem;
  margin-bottom: 0.5rem;
`;

const MButton = styled(Button)`
  width: 10rem;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color:#eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const List = styled.li`
  margin: 0.5rem 0;
  list-style-type : circle;
`;
