import { storage } from './index';

// NOTE: 삭제할 firebase storage directory path 를 받아, listAll 로 파일 목록을 불러온 후 파일을 재귀적으로 삭제.
export function deleteFolderContents(path: string) {
  const ref = storage.refFromURL(path);
  ref.listAll()
    .then(dir => {
      dir.items.forEach(fileRef => {
        deleteFile(ref.fullPath, fileRef.name);
      });
      dir.prefixes.forEach(folderRef => {
        deleteFolderContents(`gs://${folderRef.bucket}/${folderRef.fullPath}`);
      });
    })
    .catch(() => {
      alert('firebase storage 데이터 삭제에 실패했습니다.');
    });
}

export function deleteFile(pathToFile: string, fileName: string) {
  const ref = storage.ref(pathToFile);
  const childRef = ref.child(fileName);
  childRef.delete().then(() => {
    console.log(`firebase storage ${pathToFile}/${fileName} 데이터 삭제 성공`);
  }).catch(() => {
    console.log(`firebase storage ${pathToFile}/${fileName} 데이터 삭제 실패`);
  });
}
