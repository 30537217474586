import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { isEmptyString } from '../../util/function/validation';
import { storage } from '../../util/firebase';
import PostDetail from '../../templates/PostDetail';
import { getPostQuery, updatePostQuery } from '../../util/apollo/postQueries';

interface Props {
  type: 0 | 1;
}

const UpdatePost: React.FC<Props> = ({ type }) => {
  const typeKor = useMemo(() => (type === 0 ? '공지' : '뉴스'), [type]);
  const typeEng = useMemo(() => (type === 0 ? 'notice' : 'news'), [type]);

  const [title, setTitle] = useState('');
  const [titleEng, setTitleEng] = useState('');
  const [body, setBody] = useState('');
  const [bodyEng, setBodyEng] = useState('');
  const [priority, setPriority] = useState<0 | 1>(1);

  // 첨부파일
  const [attachments, setAttachments] = useState<string | null>(null);
  const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);
  const onRemoveAttachments = () => {
    setAttachmentFiles([]);
    setAttachments(null);
  };

  // 썸네일
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  const storageRef = storage.ref();
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setThumbnailFile(e.target.files?.[0] ?? null);
  };
  const onRemoveThumbnail = () => {
    setThumbnailFile(null);
    setThumbnail(null);
  };

  // 기존 정보 불러오기
  const { id }: { id: string } = useParams();
  const { data } = useQuery(getPostQuery, { fetchPolicy: 'network-only', variables: { id: Number(id) } });
  useEffect(() => {
    if (data) {
      const post = data.getPost;
      setTitle(post.title);
      setTitleEng(post.titleEng);
      setPriority(post.priority);
      setThumbnail(post.thumbnail);
      setAttachments(post.attachments);
      {
        const gsRef = storage.refFromURL(`gs://stem-dc04f.appspot.com/${typeEng}/${id}/content/content.txt`);
        gsRef.getDownloadURL().then((url) => {
          fetch(url).then(response => response.text().then(text => {
            setBody(text);
          }));
        });
      }
      {
        const gsRef = storage.refFromURL(`gs://stem-dc04f.appspot.com/${typeEng}/${id}/content/contentEng.txt`);
        gsRef.getDownloadURL().then((url) => {
          fetch(url).then(response => response.text().then(text => {
            setBodyEng(text);
          }));
        });
      }
    }
  }, [data]);

  // 수정 로직
  const [updatePost, { loading, error }] = useMutation(updatePostQuery);
  const [loadingFile, setLoadingFile] = useState(false);
  const submit = async () => {
    if (isEmptyString(title) || isEmptyString(body)) {
      alert('제목, 내용을 입력해주세요.');
      return;
    }

    let fileRef = null;
    setLoadingFile(true);

    if (thumbnailFile) {
      fileRef = storageRef.child(`${typeEng}/${id}/thumbnail/${id}`);
      fileRef.put(thumbnailFile).catch(() => {
        alert('이미지 업로드 실패');
      });
    }
    if (!thumbnailFile && !thumbnail) {
      const gsRef = storage.refFromURL(`gs://stem-dc04f.appspot.com/${typeEng}/${id}/thumbnail/${id}`);
      gsRef.delete().then(() => {
        console.log(`${title} ${typeKor}의 썸네일을 삭제했습니다.`);
      }).catch(() => console.log(`${title} ${typeKor}의 썸네일이 없습니다.`));
    }

    fileRef = storageRef.child(`${typeEng}/${id}/content/content.txt`);
    fileRef.put(new Blob([body], { type: 'text/plain;charset=utf-8' })).catch(() => {
      alert('body 업로드 실패');
    });

    fileRef = storageRef.child(`${typeEng}/${id}/content/contentEng.txt`);
    setLoadingFile(true);
    fileRef.put(new Blob([bodyEng], { type: 'text/plain;charset=utf-8' })).catch(() => {
      alert('bodyEng 업로드 실패');
    });

    attachmentFiles.forEach((attachment) => {
      fileRef = storageRef.child(`${typeEng}/${id}/attachments/${attachment.name}`);
      fileRef.put(attachment).catch(() => {
        alert('첨부파일 업로드 실패');
      });
    });

    // db body, thumbnail, attachments 값 업데이트
    await updatePost({
      variables: {
        id: Number(id),
        title: title.trim(),
        titleEng: titleEng.trim(),
        body: JSON.stringify({
          preview: body.replace(/(<([^>]+)>)/gi, '').slice(0, 200),
          content: `gs://stem-dc04f.appspot.com/${typeEng}/${id}/content/content.txt`,
        }),
        bodyEng: JSON.stringify({
          preview: bodyEng.replace(/(<([^>]+)>)/gi, '').slice(0, 200),
          content: `gs://stem-dc04f.appspot.com/${typeEng}/${id}/content/contentEng.txt`,
        }),
        priority,
        thumbnail: (thumbnailFile || thumbnail) ? `gs://stem-dc04f.appspot.com/${typeEng}/${id}/thumbnail/${id}` : null,
        attachments: attachmentFiles.length > 0 ? JSON.stringify(attachmentFiles.map(attachment => `${typeEng}/${id}/attachments/${attachment.name}`)) : attachments,
      },
    });

    setLoadingFile(false);
    alert(`${typeKor} 수정 성공!`);
  };

  const [trigger, setTrigger] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const toggleTrigger = () => setTrigger(state => !state);
  useEffect(() => {
    if (isMounted) {
      submit().then();
    } else {
      setIsMounted(true);
    }
  }, [trigger]);

  if (loading || loadingFile) return <p>수정 중입니다...</p>;
  if (error) return <p>에러가 발생했습니다.</p>;
  return (
    <div>
      <Title>
        {typeKor}
        {' '}
        수정
      </Title>
      <PostDetail
        title={title}
        setTitle={setTitle}
        titleEng={titleEng}
        setTitleEng={setTitleEng}
        body={body}
        setBody={setBody}
        bodyEng={bodyEng}
        setBodyEng={setBodyEng}
        setAttachments={setAttachmentFiles}
        priority={priority}
        setPriority={setPriority}
        submit={toggleTrigger}
        onChangeThumbnailFile={onFileUpload}
        onClickRemoveThumbnail={onRemoveThumbnail}
        onClickRemoveAttachments={onRemoveAttachments}
        thumbnailFile={thumbnailFile}
        originalThumbnail={thumbnail}
        originalAttachments={attachments}
      />
    </div>
  );
};

export default UpdatePost;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;
