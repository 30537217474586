import {
  InMemoryCache, ApolloClient, HttpLink, ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const httpLink = new HttpLink({ uri: 'https://api.gongwoo.snu.ac.kr/graphql' });

const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = sessionStorage.getItem('accessToken') || null;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: (accessToken === null) ? '' : `Bearer ${accessToken}`,
    },
  }));

  return forward(operation);
});

const logoutLink = onError(({ networkError }) => {
  if (networkError) {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    window.location.replace('/');
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink.concat(authMiddleware).concat(httpLink),
});

export default client;
