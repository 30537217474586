import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { storage } from '../../util/firebase';
import { isEmptyString } from '../../util/function/validation';
import MemberDetail from '../../templates/MemberDetail';
import { getMemberQuery, updateMemberQuery } from '../../util/apollo/memberQueries';

const UpdateMember: React.FC = () => {
  const [name, setName] = useState('');
  const [nameEng, setNameEng] = useState('');
  const [department, setDepartment] = useState('');
  const [departmentEng, setDepartmentEng] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [introductionEng, setIntroductionEng] = useState('');

  const [active, setActive] = useState<0 | 1>(0);
  const [generation, setGeneration] = useState('12.5');
  const [picture, setPicture] = useState<string | null>(null);

  // 기존 정보 불러오기
  const { id }: { id: string } = useParams();
  const { data } = useQuery(getMemberQuery, { fetchPolicy: 'network-only', variables: { id: Number(id) } });
  useEffect(() => {
    if (data) {
      const member = data.getMember;
      setName(member.name);
      setNameEng(member.nameEng);
      setDepartment(member.department);
      setDepartmentEng(member.departmentEng);
      setIntroduction(member.introduction);
      setIntroductionEng(member.introductionEng);
      setActive(member.active);
      setGeneration(member.generation);
      setPicture(member.picture);
    }
  }, [data]);

  // 이미지
  const storageRef = storage.ref();
  const [file, setFile] = useState<File | null>(null);
  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] ?? null);
  };
  const onRemovePicture = () => {
    setFile(null);
    setPicture(null);
  };

  // 수정 로직
  const [updateMember, { loading, error }] = useMutation(updateMemberQuery);
  const [loadingFile, setLoadingFile] = useState(false);
  const submit = async () => {
    if (isEmptyString(name) || isEmptyString(department)) {
      alert('이름, 학부를 입력해주세요.');
      return;
    }

    if (file) {
      const fileRef = storageRef.child(`member/${id}/picture/${id}`);
      try {
        setLoadingFile(true);
        await fileRef.put(file);
        console.log('이미지 업로드 성공!');
      } catch {
        alert('이미지 업로드 실패');
        return;
      } finally {
        setLoadingFile(false);
      }
    }

    if (!file && !picture) {
      const gsRef = storage.refFromURL(`gs://stem-dc04f.appspot.com/member/${id}/picture/${id}`);
      gsRef.delete().then(() => {
        console.log(`${name} 멤버의 사진을 삭제했습니다.`);
      }).catch(() => console.log(`${name} 멤버의 사진이 없습니다.`));
    }

    await updateMember({
      variables: {
        id: Number(id),
        name: name.trim(),
        nameEng: nameEng.trim(),
        department: department.trim(),
        departmentEng: departmentEng.trim(),
        generation,
        introduction,
        introductionEng,
        active,
        picture: (file || picture) ? `gs://stem-dc04f.appspot.com/member/${id}/picture/${id}` : null,
      },
    });
    alert('멤버 수정 성공!');
  };

  if (loading || loadingFile) return <p>수정 중입니다...</p>;
  if (error) return <p>에러가 발생했습니다.</p>;
  return (
    <div>
      <Title>멤버 수정</Title>
      <MemberDetail
        name={name}
        setName={setName}
        nameEng={nameEng}
        setNameEng={setNameEng}
        introduction={introduction}
        setIntroduction={setIntroduction}
        introductionEng={introductionEng}
        setIntroductionEng={setIntroductionEng}
        department={department}
        setDepartment={setDepartment}
        departmentEng={departmentEng}
        setDepartmentEng={setDepartmentEng}
        generation={generation}
        setGeneration={setGeneration}
        active={active}
        setActive={setActive}
        submit={submit}
        file={file}
        onChangePictureFile={onFileUpload}
        onClickRemovePicture={onRemovePicture}
        originalPicture={picture}
      />
    </div>
  );
};

export default UpdateMember;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;
