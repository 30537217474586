import { gql } from '@apollo/client';

export const getAllPostsQuery = gql`
  query GetAllPosts($type: Int!){
    getPostsPage(
      input: {
        type: $type
        page: 0
        size: 100
      }
    ) {
      result {
        id
        title
        priority
        thumbnail
        attachments
      }
    }
  }
`;

export const getPostQuery = gql`
  query GetPost($id: Int!) {
    getPost(
      id: $id
    ) {
      id
      title
      titleEng
      body
      bodyEng
      priority
      thumbnail
      attachments
    }
  }
`;

export const createPostQuery = gql`
  mutation CreatePost(
    $type: Int!, $title: String!, $titleEng: String!, $body: String!, $bodyEng: String!, $priority: Int!, $thumbnail: String, $attachments: String
  ){
    createPost(
      input: {
        type: $type
        title: $title
        titleEng: $titleEng
        body: $body
        bodyEng: $bodyEng
        priority: $priority
        thumbnail: $thumbnail
        attachments: $attachments
      }
    ) {
      id
      title
      titleEng
      body
      bodyEng
      priority
      thumbnail
      attachments
    }
  }
`;

export const updatePostQuery = gql`
  mutation UpdatePost(
    $id: Int!, $title: String!, $titleEng: String!, $body: String!, $bodyEng: String!, $priority: Int!, $thumbnail: String, $attachments: String
  ){
    updatePost(
      input: {
        id: $id
        title: $title
        titleEng: $titleEng
        body: $body
        bodyEng: $bodyEng
        priority: $priority
        thumbnail: $thumbnail
        attachments: $attachments
      }
    ) {
      id
      title
      priority
      thumbnail
    }
  }
`;

export const deletePostQuery = gql`
  mutation DeletePost($id: Int!) {
    deletePost(id: $id)
  }
`;

export const refreshPostsQuery = gql`
  mutation RefreshPosts {
    refreshPosts
  }
`;
