import React from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Member from '../../util/interfaces/member';
import { deleteMemberQuery, getAllMembersQuery } from '../../util/apollo/memberQueries';
import { storage } from '../../util/firebase';

interface Props {
  isAuthorized: boolean;
}

const MemberList: React.FC<Props> = ({ isAuthorized }) => {
  const {
    data, loading, error, refetch,
  } = useQuery(getAllMembersQuery, {
    fetchPolicy: 'network-only',
  });

  const [deleteMemberByQuery] = useMutation(deleteMemberQuery);
  const deleteMember = (member: Member) => {
    if (window.confirm(`${member.name} 멤버를 영구 삭제합니다.`)) {
      // db 데이터 삭제
      deleteMemberByQuery({ variables: { id: member.id } }).then(() => {
        refetch().then(
          _ => {
            // firebase storage 사진 삭제
            if (member.picture) {
              const gsRef = storage.refFromURL(member.picture);
              gsRef.delete().then(() => {
                console.log(`${member.name} 멤버의 사진을 삭제했습니다.`);
              });
            }
          }
        );
        alert(`${member.name} 멤버를 삭제했습니다.`);
      });
    }
  };

  if (loading) return <p>로딩 중 입니다...</p>;
  if (error) return <p>데이터를 불러오는데 실패했습니다.</p>;
  return (
    <div>
      <Title>멤버 목록</Title>
      {data.getMembers.result.map((member: Member) => (
        <Row key={member.id}>
          <p className="id">{member.id}</p>
          <p className="name">{member.name}</p>
          <p className="generation">
            {member.generation}
            기
          </p>
          <p className="is-active">{member.active ? '활동' : '비활동'}</p>
          {
            !isAuthorized ? null
              : (
                <>
                  <Link to={`/members/${member.id}`}>수정</Link>
                  <button type="button" onClick={() => deleteMember(member)}>삭제</button>
                </>
              )
          }
        </Row>
      )).reverse()}
    </div>
  );
};
export default MemberList;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  
  padding: 1rem;
  border-top: 1px solid #f68e1e;
  &:last-of-type {
    border-bottom: 1px solid #f68e1e;
  }
  &:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
  }
  
  & > p {
    &.id {
      width: 10%;
    } 
    &.name {
      width: 20%;
    } 
    &.generation {
      width: 25%;
    }
    &.is-active {
      width: 20%;
    }
  }
  
  & > button {
    width: 15%;
    background: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
    color: red;
  }
  & > a {
    width: 10%;
    text-align: center;
    text-decoration: none;
  }
`;
