import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { signInQuery } from '../util/apollo/authQueries';

interface Props {
  isAuthorized: boolean;
  setIsAuthorized: (newState: boolean) => void;
}

const AuthPage: React.FC<Props> = ({ isAuthorized, setIsAuthorized }) => {
  const inputProps = (state: string, setState: (newState: string) => void) => ({
    value: state,
    onChange: (e: ChangeEvent<HTMLInputElement>) => setState(e.target.value),
  });

  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  // signIn query
  const [doSignIn, { data }] = useLazyQuery(signInQuery, {
    onError: (error) => {
      alert(error.message);
    },
    fetchPolicy: 'no-cache',
    variables: { email: confirmedEmail, password: confirmedPassword },
  });

  // execute signIn
  const signIn = () => {
    setConfirmedEmail(email);
    setConfirmedPassword(password);
    doSignIn();
  };

  // sessionStorage accessToken 관리
  useEffect(() => {
    if (data) {
      sessionStorage.setItem('accessToken', data.signin.accessToken);
      sessionStorage.setItem('refreshToken', data.signin.refreshToken);
      setIsAuthorized(true);
    }
  }, [data]);

  // TODO: fix disable logic
  const disableLoginButton = email.length === 0 || password.length === 0;

  return (
    <div>
      <Title>{!isAuthorized ? '로그인' : '메인 화면'}</Title>
      {!isAuthorized && (
        <Form onSubmit={(e) => e.preventDefault()}>
          <TextField
            id="email"
            label="이메일"
            type="email"
            {...inputProps(email, setEmail)}
          />
          <TextField
            id="password"
            label="비밀번호"
            type="password"
            {...inputProps(password, setPassword)}
          />

          <Button
            variant="contained"
            color="primary"
            disabled={disableLoginButton}
            onClick={signIn}
          >
            로그인
          </Button>
        </Form>
      )}
    </div>
  );
};

export default AuthPage;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
