import { gql } from '@apollo/client';

export const getAllMembersQuery = gql`
  query {
    getMembers(
      input: {
        active: 0
        generation: null
      }
    ) {
      result {
        id
        name
        generation
        active
        picture
      }
    }
  }
`;

export const getMemberQuery = gql`
  query GetMember($id: Int!) {
    getMember(
      id: $id
    ) {
      id
      picture
      name
      introduction
      introductionEng
      nameEng
      department
      departmentEng
      generation
      active
      createdAt
      updatedAt
    }
  }
`;

export const deleteMemberQuery = gql`
  mutation DeleteMember($id: Int!) {
    deleteMember(id: $id)
  }
`;

export const createMemberQuery = gql`
  mutation CreateMember(
    $name: String!, $nameEng: String!, $department: String!, $departmentEng: String!,
    $generation: String!, $introduction: String, $introductionEng: String, $active: Int!, $picture: String
  ){
    createMember(
      input: {
        name: $name
        nameEng: $nameEng
        department: $department
        departmentEng: $departmentEng
        generation: $generation
        introduction: $introduction
        introductionEng: $introductionEng
        active: $active
        picture: $picture
      }
    ) {
      id
      picture
      name
      introduction
      introductionEng
      nameEng
      departmentEng
      department
      generation
      active
      createdAt
      updatedAt
    }
  }
`;

export const updateMemberQuery = gql`
  mutation UpdateMember(
    $id: Int!, $name: String!, $nameEng: String!, $department: String!, $departmentEng: String!,
    $generation: String!, $introduction: String, $introductionEng: String, $active: Int!, $picture: String
  ){
    updateMember(
      input: {
        id: $id
        name: $name
        nameEng: $nameEng
        department: $department
        departmentEng: $departmentEng
        generation: $generation
        introduction: $introduction
        introductionEng: $introductionEng
        active: $active
        picture: $picture
      }
    ) {
      id
    }
  }
`;
