import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { isEmptyString } from '../../util/function/validation';
import { storage } from '../../util/firebase';
import PostDetail from '../../templates/PostDetail';
import { createPostQuery, updatePostQuery } from '../../util/apollo/postQueries';

interface Props {
  type: 0 | 1;
}

const CreatePost: React.FC<Props> = ({ type }) => {
  const typeKor = useMemo(() => (type === 0 ? '공지' : '뉴스'), [type]);
  const typeEng = useMemo(() => (type === 0 ? 'notice' : 'news'), [type]);

  const [title, setTitle] = useState('');
  const [titleEng, setTitleEng] = useState('');
  const [body, setBody] = useState('');
  const [bodyEng, setBodyEng] = useState('');
  const [priority, setPriority] = useState<0 | 1>(1);

  // 첨부파일
  const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);
  const onRemoveAttachments = () => {
    setAttachmentFiles([]);
  };

  // 썸네일
  const storageRef = storage.ref();
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setThumbnailFile(e.target.files?.[0] ?? null);
  };
  const onRemoveThumbnail = () => {
    setThumbnailFile(null);
  };

  // 생성 로직
  const [createPost, { loading, error }] = useMutation(createPostQuery);
  const [updatePost] = useMutation(updatePostQuery);
  const [loadingFile, setLoadingFile] = useState(false);
  const submit = async () => {
    if (isEmptyString(title) || isEmptyString(body)) {
      alert('제목, 내용을 입력해주세요.');
      return;
    }

    const newPost = (await createPost({
      variables: {
        type,
        title: title.trim(),
        titleEng: titleEng.trim(),
        body: '',
        bodyEng: '',
        priority,
      },
    })).data.createPost;

    let fileRef = null;
    setLoadingFile(true);

    if (thumbnailFile) {
      fileRef = storageRef.child(`${typeEng}/${newPost.id}/thumbnail/${newPost.id}`);
      fileRef.put(thumbnailFile).catch(() => {
        alert('이미지 업로드 실패');
      });
    }

    fileRef = storageRef.child(`${typeEng}/${newPost.id}/content/content.txt`);
    fileRef.put(new Blob([body], { type: 'text/plain;charset=utf-8' })).catch(() => {
      alert('body 업로드 실패');
    });

    fileRef = storageRef.child(`${typeEng}/${newPost.id}/content/contentEng.txt`);
    fileRef.put(new Blob([bodyEng], { type: 'text/plain;charset=utf-8' })).catch(() => {
      alert('bodyEng 업로드 실패');
    });

    attachmentFiles.forEach((attachment) => {
      fileRef = storageRef.child(`${typeEng}/${newPost.id}/attachments/${attachment.name}`);
      fileRef.put(attachment).catch(() => {
        alert('첨부파일 업로드 실패');
      });
    });

    // db body, thumbnail, attachments 값 업데이트
    await updatePost({
      variables: {
        id: newPost.id,
        title: newPost.title,
        titleEng: newPost.titleEng,
        body: JSON.stringify({
          preview: body.replace(/(<([^>]+)>)/gi, '').slice(0, 200),
          content: `gs://stem-dc04f.appspot.com/${typeEng}/${newPost.id}/content/content.txt`,
        }),
        bodyEng: JSON.stringify({
          preview: bodyEng.replace(/(<([^>]+)>)/gi, '').slice(0, 200),
          content: `gs://stem-dc04f.appspot.com/${typeEng}/${newPost.id}/content/contentEng.txt`,
        }),
        priority: newPost.priority,
        thumbnail: thumbnailFile ? `gs://stem-dc04f.appspot.com/${typeEng}/${newPost.id}/thumbnail/${newPost.id}` : null,
        attachments: attachmentFiles.length > 0 ? JSON.stringify(attachmentFiles.map(attachment => `${typeEng}/${newPost.id}/attachments/${attachment.name}`)) : null,
      },
    });

    setLoadingFile(false);
    alert(`${typeKor} 등록 성공!`);
  };

  const [trigger, setTrigger] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const toggleTrigger = () => setTrigger(state => !state);
  useEffect(() => {
    if (isMounted) {
      submit().then();
    } else {
      setIsMounted(true);
    }
  }, [trigger]);

  if (loading || loadingFile) return <p>생성 중입니다...</p>;
  if (error) return <p>에러가 발생했습니다.</p>;
  return (
    <div>
      <Title>
        {typeKor}
        {' '}
        생성
      </Title>
      <PostDetail
        title={title}
        setTitle={setTitle}
        titleEng={titleEng}
        setTitleEng={setTitleEng}
        body={body}
        setBody={setBody}
        bodyEng={bodyEng}
        setBodyEng={setBodyEng}
        setAttachments={setAttachmentFiles}
        priority={priority}
        setPriority={setPriority}
        submit={toggleTrigger}
        onChangeThumbnailFile={onFileUpload}
        onClickRemoveThumbnail={onRemoveThumbnail}
        onClickRemoveAttachments={onRemoveAttachments}
        thumbnailFile={thumbnailFile}
        originalThumbnail={null}
        originalAttachments={null}
      />
    </div>
  );
};

export default CreatePost;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;
