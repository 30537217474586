import { gql } from '@apollo/client';

export const signInQuery = gql`
  query SignIn($email: String!, $password: String!) {
    signin (
      input: {
        email: $email
        password: $password
      }
    ) {
      accessToken
      refreshToken
    }
  }
`;
