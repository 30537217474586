import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  deletePostQuery,
  getAllPostsQuery,
  refreshPostsQuery,
} from '../../util/apollo/postQueries';
import { deleteFolderContents } from '../../util/firebase/deleteStorage';
import Post from '../../util/interfaces/post';

interface Props {
  type: 0 | 1;
  isAuthorized: boolean;
}

const PostList: React.FC<Props> = ({ type, isAuthorized }) => {
  const typeKor = useMemo(() => (type === 0 ? '공지' : '뉴스'), [type]);
  const typeEng = useMemo(() => (type === 0 ? 'notice' : 'news'), [type]);
  const typeUrl = useMemo(() => (type === 0 ? 'notices' : 'news'), [type]);

  const {
    data, loading, error, refetch,
  } = useQuery(getAllPostsQuery, {
    fetchPolicy: 'network-only',
    variables: { type },
  });

  const [deletePostByQuery] = useMutation(deletePostQuery);
  const deletePost = (post: Post) => {
    if (window.confirm(`${post.title} ${typeKor}를 영구 삭제합니다.`)) {
      // db 데이터 삭제
      deletePostByQuery({ variables: { id: post.id } }).then(() => {
        refetch().then(
          _ => {
            // firebase storage 데이터 삭제
            deleteFolderContents(`gs://stem-dc04f.appspot.com/${typeEng}/${post.id}`);
          }
        );
        alert(`${post.title} ${typeKor}를 삭제했습니다.`);
      });
    }
  };

  const [doRefreshPosts] = useMutation(refreshPostsQuery);
  const refreshPosts = () => {
    doRefreshPosts().then(
      (result) => {
        if (result.data.refreshPosts) {
          alert('적용했습니다. 3분 뒤 확인해 주세요.');
        } else {
          alert('오류가 발생했습니다. 다시 시도해 주세요.');
        }
      }
    ).catch(
      (err) => {
        alert(err.message);
      }
    );
  };

  if (loading) return <p>로딩 중 입니다...</p>;
  if (error) return <p>데이터를 불러오는데 실패했습니다.</p>;
  return (
    <div>
      <Title>
        {typeKor}
        {' '}
        목록
      </Title>
      <Button variant="contained" color="primary" onClick={refreshPosts}>
        적용하기
      </Button>
      <br />
      <br />
      {data.getPostsPage.result.map((post: Post) => (
        <Row key={post.id}>
          <p className="id">{post.id}</p>
          <p className="title">{post.title}</p>
          <p className="priority">{post.priority === 0 ? '상단고정' : '-'}</p>
          {
            !isAuthorized ? null
              : (
                <>
                  <Link to={`/${typeUrl}/${post.id}`}>수정</Link>
                  <button type="button" onClick={() => deletePost(post)}>
                    삭제
                  </button>
                </>
              )
          }
        </Row>
      ))}
    </div>
  );
};
export default PostList;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  padding: 1rem;
  border-top: 1px solid #f68e1e;
  &:last-of-type {
    border-bottom: 1px solid #f68e1e;
  }
  &:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
  }

  & > p {
    &.id {
      width: 10%;
    }
    &.title {
      width: 50%;
    }
    &.priority {
      width: 15%;
    }
  }

  & > button {
    width: 15%;
    background: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
    color: red;
  }
  & > a {
    width: 10%;
    text-align: center;
    text-decoration: none;
  }
`;
