import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import GlobalStyle from './static/style/globalStyle';
import MemberList from './pages/member/MemberList';
import CreateMember from './pages/member/CreateMember';
import UpdateMember from './pages/member/UpdateMember';
import PostList from './pages/post/PostList';
import CreatePost from './pages/post/CreatePost';
import UpdatePost from './pages/post/UpdatePost';
import AuthPage from './pages/Auth';

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const signOut = () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    window.location.replace('/');
  };

  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <Header>
          <Navigation>
            <ul>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/">
                    메인 화면
                  </Link>
                </Button>
              </li>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/members">
                    멤버 목록
                  </Link>
                </Button>
              </li>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/members/create">
                    멤버 생성
                  </Link>
                </Button>
              </li>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/notices">
                    공지 목록
                  </Link>
                </Button>
              </li>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/notices/create">
                    공지 생성
                  </Link>
                </Button>
              </li>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/news">
                    뉴스 목록
                  </Link>
                </Button>
              </li>
              <li>
                <Button size="large">
                  <Link className="navLink" to="/news/create">
                    뉴스 생성
                  </Link>
                </Button>
              </li>
            </ul>
          </Navigation>
          {isAuthorized ? (
            <Button size="large" onClick={signOut}>
              로그아웃
            </Button>
          ) : (
            <Button size="large">
              <Link className="navLink" to="/">
                로그인
              </Link>
            </Button>
          )}
        </Header>
        <hr />
        <Main>
          <Switch>
            {/* 멤버 CRUD */}
            <Route path="/members" exact>
              <MemberList isAuthorized={isAuthorized} />
            </Route>
            <Route path="/members/create" exact>
              <CreateMember />
            </Route>
            <Route path="/members/:id" exact>
              <UpdateMember />
            </Route>
            {/* 공지 CRUD */}
            <Route path="/notices" exact>
              <PostList isAuthorized={isAuthorized} type={0} />
            </Route>
            <Route path="/notices/create" exact>
              <CreatePost type={0} />
            </Route>
            <Route path="/notices/:id" exact>
              <UpdatePost type={0} />
            </Route>
            {/* 뉴스 CRUD */}
            <Route path="/news" exact>
              <PostList isAuthorized={isAuthorized} type={1} />
            </Route>
            <Route path="/news/create" exact>
              <CreatePost type={1} />
            </Route>
            <Route path="/news/:id" exact>
              <UpdatePost type={1} />
            </Route>
            <Route>
              <AuthPage
                isAuthorized={isAuthorized}
                setIsAuthorized={setIsAuthorized}
              />
            </Route>
          </Switch>
        </Main>
      </Router>
    </div>
  );
}

export default App;

const Main = styled.main`
  padding: 2rem;
`;

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const Navigation = styled.nav`
  ul {
    display: flex;
    gap: 1rem;
  }
`;
